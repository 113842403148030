<template>
  <div class="login center">
    
    <div class="login_mian">
      <div class="login_mian_logo margin-auto">
        <img src="../../assets/image/login/logo.png" alt="">
      </div>
      <div class="login_con">
        <div class="login_title">新有居管理平台</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm m-t-60">
          <ul class="login_mian_ul">
            <li>
              <div class="login_mian_img center">
                <img src="../../assets/image/login/username.png" alt="">
              </div>
              <div class="login_mian_ipt f-1">
                <el-form-item prop="username">
                  <el-input maxlength="11" v-model="ruleForm.username" placeholder="请输入账号"></el-input>
                </el-form-item>
              </div>
            </li>
            <li>
              <div class="login_mian_img center">
                <img src="../../assets/image/login/password.png" alt="">
              </div>
              <div class="login_mian_ipt f-1">
                <el-form-item prop="password">
                  <el-input type="password" v-model="ruleForm.password" placeholder="请输入密码" @keyup.enter.native="login('ruleForm')"></el-input>
                </el-form-item>
              </div>
            </li>
          </ul>
          <el-button class="login_btn" @click="login('ruleForm')">登录</el-button>
          <div class="login_mian_footer flex">
            <div class="login_footer_img flexs" @click="changePassword">
              <img src="../../assets/image/login/select_pink.png" alt="" v-if="isPassword">
              <img src="../../assets/image/login/select.png" alt="" v-else>
              <span>记住用户密码</span>
            </div>
            <div class="login_footer_txt" @click="forgetPassword">忘记密码？</div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      ruleForm: {
        username:'',//15800000000
        // password:'123456'
        // username:'18500000000',
        password:'',
        type:1,//2小程序
      },
      isPassword:false,//是否记住密码
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods:{
    login (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.loginPc(this.ruleForm).then(res=>{
            this.$message({message: res.msg,type: 'success'});
            this.$store.commit('SET_TOKEN',res.data.token)
            this.$axios.userInfo().then(res => {
              
              this.$store.commit('SET_USERINFO', res.data)
              if (res.data.position == 5) {
                this.$router.replace({path:'/personage/Staff'})
              } else {
                this.$router.push({ path: '/' })
              }
            })
          })
        }
      });
    },
    //忘记密码
    forgetPassword () {
      this.$router.push({name:'ForgetPassword'})
    },
    //记住用户mim
    changePassword () {
      this.isPassword = !this.isPassword
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: url('../../assets/image/login/bj.png') no-repeat;
  background-size: cover;
  .login_mian_logo {
    width: 268px;
    height: 74px;
  }
  .login_con {
    width: 729px;
    height: 680px;
    padding: 140px 140px 0 140px;
    background: url('../../assets/image/login/kunag.png') no-repeat;
    background-size: cover;
    .login_title {
      color: #FFFFFF;
      font-size: 36px;
      text-align: center;
    }
    .login_mian_ul {
      li {
        display: flex;
        align-items: center;
        height: 64px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        &:first-child {
          margin-bottom: 30px;
        }
        .login_mian_img {
          width: 68px;
          height: 64px;
          position: relative;
          img {
            width: 28px;
            height: 28px;
          }
          &::before {
            top: 50%;
            width: 1px;
            height: 32px;
            right: 0;
            content: '';
            transform: translateY(-50%);
            position: absolute;
            background: rgba(255, 255, 255, 0.5);
          }
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
        }
        /deep/ .el-form-item__error  {
          top: 140%;
        }
        /deep/ .el-input__inner {
          width: 100%;
          border: none;
          font-size: 16px;
          color: #FFFFFF;
          background: none;
        }
        /deep/ .el-input__inner::placeholder {
          color: #FFFFFF;
        }
        /* 谷歌 */
        /deep/ .el-input__inner::-webkit-input-placeholder {
          color: #FFFFFF;
        }
        /* 火狐 */
        /deep/ .el-input__inner:-moz-placeholder {
          color: #FFFFFF;
        }
        /*ie*/
        /deep/ .el-input__inner:-ms-input-placeholder {
          color: #FFFFFF;
        }
      }
    }
    .login_btn {
      width: 100%;
      height: 64px;
      margin: 40px 0 20px 0;
      border: none;
      color: #FFFEFE;
      font-size: 24px;
      background: linear-gradient(180deg, #1372F5, #00A8F7);
      border-radius: 8px;
    }
    .login_footer_img {
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      span {
        color: #FFFFFF;
        font-size: 16px;
      }
      cursor: pointer;
    }
    .login_footer_txt  {
      color: #FFFFFF;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

</style>